import { useState } from "react";
import { string } from "yup";
import { apiUrl } from "../../../../../config";
import api from "../../../../../services/api"
import { downloaditPost } from "../../../../../utils/download";

export default function useMedicalReport() {

    const [medicals, setMedicals] = useState<Record<string, any>[]>([]);
    const [count, setCount] = useState('0');

    const fromDate = '2024-12-01T00:00:02.567Z';
    const toDate = '2025-01-01T00:00:02.567Z'

    const loadStudents = async () => {
        const students = await api.get('/students', { params: { limitItems: 10000, where: { updatedAt: { "$gt": fromDate }, position: 'Estudante', document_number: { '$exists': true } } } });
        return students?.data?.rows || [];
    }

    const loadProfissionais = async () => {
        const students = await api.get('/students', { params: { limitItems: 10000, where: { updatedAt: { "$gt": fromDate }, position: 'Profissional da área da saúde', document_number: { '$exists': true } } } });
        return students?.data?.rows || [];
    }

    const loadOthers = async () => {
        const students = await api.get('/students', { params: { limitItems: 10000, where: { "$and": [{ updatedAt: { "$gt": fromDate } }, { "$or": [{ position: 'Outro? Qual?' }, { position: 'Outro' }] }, { document_number: { '$exists': true } }] } } });
        return students?.data?.rows || [];
    }


    const loadMedicals = async () => {
        const students = await api.get('/students', { params: { limitItems: 10000, where: { updatedAt: { "$gt": fromDate }, position: 'Médico', document_number: { '$exists': true } } } });
        return students?.data?.rows || [];
    }

    const loadAndApply = async (student, from = '', until = '') => {

        const attendance = await api.post(`/user-participation/${student?._id?.toString()}`, { from, until });

        student.count = attendance?.data?.count;

        return student;
    }


    const loadAttendances = async (students, from = '', until = '') => {

        let x = 0;

        while (x < students?.length) {

            const student = students?.[x];

            const promises: Promise<any>[] = [];

            promises.push(loadAndApply(student, from, until));
            if (students[x + 1]) {
                promises.push(loadAndApply(students[x + 1], from, until));
            }
            if (students[x + 2]) {
                promises.push(loadAndApply(students[x + 2], from, until));
            }
            if (students[x + 3]) {
                promises.push(loadAndApply(students[x + 3], from, until));
            }
            if (students[x + 4]) {
                promises.push(loadAndApply(students[x + 4], from, until));
            }
            if (students[x + 5]) {
                promises.push(loadAndApply(students[x + 5], from, until));
            }
            if (students[x + 6]) {
                promises.push(loadAndApply(students[x + 6], from, until));
            }
            if (students[x + 7]) {
                promises.push(loadAndApply(students[x + 7], from, until));
            }
            if (students[x + 8]) {
                promises.push(loadAndApply(students[x + 8], from, until));
            }
            if (students[x + 9]) {
                promises.push(loadAndApply(students[x + 9], from, until));
            }
            if (students[x + 10]) {
                promises.push(loadAndApply(students[x + 10], from, until));
            }
            if (students[x + 11]) {
                promises.push(loadAndApply(students[x + 1], from, until));
            }
            if (students[x + 12]) {
                promises.push(loadAndApply(students[x + 2], from, until));
            }
            if (students[x + 13]) {
                promises.push(loadAndApply(students[x + 3], from, until));
            }
            if (students[x + 14]) {
                promises.push(loadAndApply(students[x + 4], from, until));
            }
            if (students[x + 15]) {
                promises.push(loadAndApply(students[x + 5], from, until));
            }
            if (students[x + 16]) {
                promises.push(loadAndApply(students[x + 6], from, until));
            }
            if (students[x + 17]) {
                promises.push(loadAndApply(students[x + 7], from, until));
            }
            if (students[x + 18]) {
                promises.push(loadAndApply(students[x + 8], from, until));
            }
            if (students[x + 19]) {
                promises.push(loadAndApply(students[x + 9], from, until));
            }
            if (students[x + 20]) {
                promises.push(loadAndApply(students[x + 10], from, until));
            }
            const response = await Promise.all(promises)

            response.map(r => {
                students[x] = r;
                x += 1;



            })



            setCount((x / students?.length).toFixed(2))

        }



        return students;


    }

    const companyMedical = async () => {

    }

    const notCompanyMedical = () => {

    }


    const allProfissionals = async () => {

        const students = await loadProfissionais();
        const medicalRecords = await loadAttendances(students, fromDate, toDate);

        const items = [['Nome', 'E-mail', 'Telefone', 'CPF', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'CRM UF', 'Empresa', 'Qual empresa?']]

        medicalRecords.filter(i => i.count > 0)?.map(student => {

            items.push([
                student?.name,
                student?.email,
                student?.phone,
                student?.document_number,
                student?.position,
                student?.category_id,
                student?.crm,
                student?.cpf_familiar,
                student?.company,
                student?.extra_4
            ])

        });

        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: items, type: 'xlsx' });

    }

    const allStudents = async () => {

        const students = await loadStudents();
        const medicalRecords = await loadAttendances(students, fromDate, toDate);

        const items = [['Nome', 'E-mail', 'Telefone', 'CPF', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'CRM UF', 'Empresa', 'Qual empresa?']]

        medicalRecords.filter(i => i.count > 0)?.map(student => {

            items.push([
                student?.name,
                student?.email,
                student?.phone,
                student?.document_number,
                student?.position,
                student?.category_id,
                student?.crm,
                student?.cpf_familiar,
                student?.company,
                student?.extra_4
            ])

        });

        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: items, type: 'xlsx' });

    }

    const allOthers = async () => {


        const students = await loadOthers();
        const medicalRecords = await loadAttendances(students, fromDate, toDate);

        const items = [['Nome', 'E-mail', 'Telefone', 'CPF', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'CRM UF', 'Empresa', 'Qual empresa?']]

        medicalRecords.filter(i => i.count > 0)?.map(student => {

            items.push([
                student?.name,
                student?.email,
                student?.phone,
                student?.document_number,
                student?.position,
                student?.category_id,
                student?.crm,
                student?.cpf_familiar,
                student?.company,
                student?.extra_4
            ])

        });
        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: items, type: 'xlsx' });
    }

    const allMedicals = async () => {

        const students = await loadMedicals();
        const medicalRecords = await loadAttendances(students, fromDate, toDate);

        const items = [['Nome', 'E-mail', 'Telefone', 'CPF', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'CRM UF', 'Empresa', 'Qual empresa?']]

        medicalRecords.filter(i => i.count > 0)?.map(student => {

            items.push([
                student?.name,
                student?.email,
                student?.phone,
                student?.document_number,
                student?.position,
                student?.category_id,
                student?.crm,
                student?.cpf_familiar,
                student?.company,
                student?.extra_4
            ])

        });

        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: items, type: 'xlsx' });

    }

    return { companyMedical, notCompanyMedical, allMedicals, count, allStudents, allProfissionals, allOthers }
}